import isEmptyLodash from 'lodash.isempty';

export function isEmpty<T>(value: T | undefined | null): value is undefined | null {
  return isEmptyLodash(value);
}

export function isExists<T>(value: T | undefined | null): value is T {
  return !isEmptyLodash(value);
}

export function emptyStringFallback<T = any>(
  value: string | null | undefined,
  fallback: T,
): string | T {
  return (value ?? '') || fallback;
}

export function emptyNumberFallback<T = any>(
  value: number | null | undefined,
  fallback: T,
): number | T {
  return (value ?? 0) || fallback;
}
